import state from './cart.state';
import getters from './cart.getters';
import mutations from './cart.mutations';
import actions from './cart.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}