export default {
  setMenu(state, data) {
    data.data = data.data.sort((a, b) => { return a.attributes.category['display-order'] - b.attributes.category['display-order'] })
    state.menu = data
  },
  icrementCheckoutAttempts(state, empty) {
    if(!empty) {
      state.checkoutAttempts++
    } else {
      state.checkoutAttempts = 0
    }
  },
  add(state, data) {
    if(!state.items.length) {
        state.items.push(data)
    } else {
      var itemexist = false;
      state.items.map(item => {

        let currentItem = {
          id: item.id,
          price: item.price,
          notes: item.notes,
          name: item.name,
          imgUrl: item.imgUrl,
          discount: 0,
          'combo-items': item['combo-items'],
          modifiers: item.modifiers,
          ingredients: item.ingredients
        }

        let incomingItem = {
          id: data.id,
          price: data.price,
          notes: data.notes,
          name: data.name,
          imgUrl: data.imgUrl,
          discount: 0,
          'combo-items': data['combo-items'],
          modifiers: data.modifiers,
          ingredients: data.ingredients
        }

        if(JSON.stringify(currentItem) == JSON.stringify(incomingItem) && !itemexist) {
          itemexist = true
          item.quantity+=data.quantity
        }

      })

      if(!itemexist) {
        state.items.push(data)
      }
    }
  },
  increase(state, index) {
    let item = state.items[index]
    item.quantity++
    state.items.splice(index, 1, item);
  },
  decrease(state, index) {
    let item = state.items[index]
    if(item.quantity) {
      item.quantity--
      state.items.splice(index, 1, item);
    }    
  },
  updateQuantity(state, { index, quantity }) {
    let item = state.items[index]
    if(item.quantity) {
      item.quantity = quantity
      state.items.splice(index, 1, item);
    }    
  },
  remove(state, index) {
    state.items.splice(index, 1);
  },
  clear(state) {
    state.items.splice(0, state.items.length)
  }
}