import state from './concept.state';
import getters from './concept.getters';
import mutations from './concept.mutations';
import actions from './concept.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
