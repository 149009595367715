import ConfigService from './config.service'
import CategoryService from './category.service'
import MenuService from './menu.service'
import OrderService from './order.service'


let Config = new ConfigService()
let Category = new CategoryService()
let Menu = new MenuService()
let Order = new OrderService()

export {
  Config,
  Category,
  Menu,
  Order
}
