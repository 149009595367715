import BaseService from "../base.service";

export default class MenuService extends BaseService {
  constructor() {
    super();
    this.baseURL = this.baseURL + "/menus";
  }

  all(page = 1) {
    const url = this.baseURL + "";

    return super.get(url, {
      page: page
    });
  }

  find(menuId) {
    const url = this.baseURL + "/" + menuId;

    return super.get(url);
  }

  upsells(menuId) {
    const url = this.baseURL + "/" + menuId + "/items?filter[upsell]=1";

    return super.get(url);
  }
}
