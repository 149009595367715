import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use( Vuetify );

export default new Vuetify( {
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#F26224',
                secondary: '#FFFFFF',
                accent: '#8c9eff',
                error: '#DF4859',
            },
        }
    }
} );
