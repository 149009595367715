import BaseService from '../base.service'

export default class CategoryService extends BaseService {

    constructor() {
        super();        
        this.baseURL = this.baseURL + '/menus/';
    }

    all(menuId, page = 1) {        
        let url = this.baseURL + menuId + '/categories?include=itemModifierGroups';

        return super.get(url, {
            page: page
        });
    }

    cdn(url_reference) {                                
        return super.getJson(url_reference + `?timestamp=${new Date().getTime()}`);        
    }

    items(menuId) {        
        let url = this.baseURL + menuId + '/items';

        return super.get(url);
    }

    find(menuId, categoryId) {        
        let url = this.baseURL + menuId + '/categories/' + categoryId;

        return super.get(url)
    }
}
