import BaseService from '../base.service'

let configService = class ConfigService extends BaseService {

    constructor() {
        super();
    }
   
    getApplications(concept = 'bk-ksa.ordernosh.com')
    {        
        let url = this.baseURL + `/applications?include=concept&filter[url]=${concept}`
        return super.get(url)
    }

    getApplicationsByKey(key)
    {        
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Solo-App': key
        }

        let url = this.baseURL + '/applications?include=concept'                
        return super.get(url)
    }

    getConcept()
    {
        let url = this.baseURL + `/clients?filter[url]=${window.location.href}`
        return super.get(url)
    }

};

export default configService
