export default {
    getMenu(state) {        
        return state.menu
    },
    items(state) {
        return state.items
    },
    checkoutAttempts(state) {
        return state.checkoutAttempts
    }
}
