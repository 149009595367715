import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import { Config, Category } from './services/SOLO'
import { mapMutations, mapGetters } from 'vuex'
import IdleVue from 'idle-vue'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  beforeCreate() {    
    this.$nextTick(() => {
      this.app()      
    })
  },
  computed: {
    ...mapGetters({
      getApplications: 'concept/getApplications',
      getConcept: 'concept/getConcept'
    })
  },
  methods: {
    ...mapMutations({
      setApplications: 'concept/setApplications',
      setConcept: 'concept/setConcept',
      setMenu: 'cart/setMenu'
    }),
    app() {
      Config.getApplicationsByKey(process.env.VUE_APP_CONCEPT_KEY).then(response => {
        const apps = response.data.data        
        const concept = response.data.included[0]
        this.setApplications({...apps.attributes, ...{id: apps.id}})
        this.setConcept({...concept.attributes,...{id: concept.i}})
        this.getAppMenu()
        this.setTheme()
      })
    },
    setTheme() {
      this.$vuetify.theme.themes.light.primary = this.getApplications.theme.primary_color
      this.$vuetify.theme.themes.light.secondary = this.getApplications.theme.secondary_color
    },
    getAppMenu() {      
      Category.cdn(this.getApplications['menu-ref']).then(response => {        
        this.setMenu(response.data)
      })
    }    
  },
  render: h => h(App)
}).$mount('#app')
